"use strict";

function toggleBodyClass(classList, event) {
  var list = classList.split(" ");
  var body = document.body;
  var bodyClassList = body.classList;

  if (event && list.length) {
    event.stopPropagation();
    list.forEach(function (c) {
      body.classList.toggle(c);
    });
  } else if (list.length) {
    list.forEach(function (c) {
      body.classList.remove(c);
    });
  }
}

document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function () {
    toggleBodyClass('has-menu-open has-search-open has-credit-open');
  });
});