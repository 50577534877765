"use strict";

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

if (document.getElementById('js-home')) {
  window.vueHome = new Vue({
    delimiters: ['[[', ']]'],
    el: '#js-home',
    data: {
      filters: {
        year: null,
        territory: '',
        levels: [],
        modes: [],
        populations: []
      },
      rawData: [],
      filteredData: [],
      domainData: [],
      domains: [],
      levels: [],
      modes: [],
      years: [],
      populations: {
        'Moins de 50 000 hab.': {
          min: 0,
          max: 50000
        },
        'De 50 000 à 100 000 hab.': {
          min: 50000,
          max: 100000
        },
        'Plus de 100 000 hab': {
          min: 100000,
          max: 99999999999
        }
      },
      region: '',
      regions: [],
      bounds: null,
      territories: [],
      territoriesObj: {},
      territoriesObjByDomain: {},
      statics: [{
        key: 'territories',
        useStorage: false,
        callback: function callback(context) {
          return context.getData();
        }
      }, {
        key: 'domains',
        useStorage: false
      }, {
        key: 'levels',
        useStorage: false
      }, {
        key: 'modes',
        useStorage: false
      }, {
        key: 'regions',
        useStorage: false
      }, {
        key: 'years',
        useStorage: false,
        params: {
          ordering: '-year'
        },
        callback: function callback(context) {
          return context.initYear();
        }
      }],
      map: null,
      markers: [],
      cluster: null,
      currentYear: null,
      currentDomain: null,
      currentTerritory: null,
      showDescription: false,
      autocomplete: [],
      focus: false,
      loading: false
    },
    mounted: function mounted() {
      var _this = this;

      this.statics.forEach(function (s, index) {
        return _this.getStaticData(s, index);
      });
    },
    computed: {
      getAutocomplete: function getAutocomplete() {
        var _this2 = this;

        return this.territories.filter(function (t) {
          return t.name.toLowerCase().includes(_this2.filters.territory.toLowerCase());
        });
      }
    },
    methods: {
      /***********************************
      GET DATA
      ***********************************/
      getStaticData: function getStaticData(path, index) {
        var _this3 = this;

        if (localStorage.getItem('igd-' + path.key) && path.useStorage) {
          this[path.key] = JSON.parse(localStorage.getItem('igd-' + path.key));

          if (path.hasOwnProperty('callback')) {
            path.callback(this);
          }
        } else {
          // http://atlas-igd.vingtcinq.io/api/v1/territories
          axios.get("/api/v1/".concat(path.key), {
            params: path.hasOwnProperty('params') ? Object.assign(path.params, {
              limit: 300
            }) : {
              limit: 300
            }
          }).then(function (response) {
            _this3[path.key] = response.data.results;
            localStorage.setItem('igd-' + path.key, JSON.stringify(_this3[path.key]));

            if (_this3.rawData.length) {
              _this3.filter();
            }

            if (path.hasOwnProperty('callback')) {
              setTimeout(function () {
                path.callback(_this3);
              }, 0);
            }
          });
        }
      },
      getData: function getData() {
        var _this4 = this;

        this.loading = true;
        axios.get('/api/v1/rawdata/stats').then(function (response) {
          setTimeout(function () {
            _this4.loading = false;
          }, 500);
          _this4.rawData = response.data;

          _this4.setTerritories();

          _this4.filter();
        });
      },
      getDataBy: function getDataBy() {
        var _this5 = this;

        var callback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};
        this.loading = true;
        var path = this.currentDomain ? 'domains' : 'stats';
        var params = this.currentDomain ? {
          domain__name: this.currentDomain.slug
        } : {};
        params = this.region != '' ? Object.assign(params, {
          region: this.region
        }) : params;
        axios.get('/api/v1/rawdata/' + path, {
          params: params
        }).then(function (response) {
          if (_this5.currentDomain) {
            _this5.domainData = response.data;

            _this5.setTerritoriesByDomain();

            _this5.filter(false);
          } else {
            _this5.rawData = response.data;

            _this5.setTerritories();

            _this5.filter();
          }

          setTimeout(function () {
            _this5.loading = false;
          }, 500);
          callback(response);
        });
      },
      initYear: function initYear() {
        this.filters.year = this.years[0].year;
      },

      /***********************************
      FILTERING
      ***********************************/
      filter: function filter() {
        var center = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.closeDescription();
        this.filteredData = this.setFilteredData(center);
        this.filterMap();
      },
      setFilteredData: function setFilteredData(center) {
        var _this6 = this;

        var data = !this.currentDomain ? _toConsumableArray(this.rawData) : _toConsumableArray(this.domainData);
        Object.keys(this.filters).map(function (key, index) {
          var filter = _this6.filters[key];

          if (key == 'year') {
            data = data.filter(function (el) {
              return el[key] == parseInt(filter);
            });
          } else if (typeof filter == 'string' && filter != '') {
            data = data.filter(function (el) {
              return el[key].includes(filter);
            });
          } else if (_typeof(filter) == 'object' && filter.length) {
            if (key == 'populations') {
              data = data.filter(function (el) {
                var population = el[key.replace('s', '')];
                return filter.reduce(function (prev, current) {
                  var filterObj = _this6.populations[current];
                  return prev || population > filterObj.min && population < filterObj.max;
                }, false);
              });
            } else {
              data = !_this6.currentDomain ? data.filter(function (el) {
                return filter.reduce(function (prev, curr) {
                  return prev || el[key].reduce(function (_prev, _curr) {
                    return _prev || _curr[key.replace('s', '')] == curr;
                  }, false);
                }, false);
              }) : data.filter(function (el) {
                return _this6.filters[key].includes(el.services[0][key.replace('s', '')].short_code);
              });
            }
          }
        });

        if (center && this.territoriesObj[this.filters.territory].hasOwnProperty('location')) {
          this.centerMap(this.territoriesObj[this.filters.territory].location);
        }

        return data;
      },
      filterByDomain: function filterByDomain(domain) {
        this.closeDescription();

        if (this.currentDomain == domain) {
          this.currentDomain = '';
        } else {
          this.currentDomain = domain;
        }

        this.getDataBy();
      },
      filterByRegion: function filterByRegion() {
        var _this7 = this;

        this.closeDescription();
        this.clearRegion();
        setTimeout(function () {
          if (_this7.region) {
            _this7.map.data.loadGeoJson(window.staticUrl + '/regions/' + _this7.region + '.geojson');
          } else {
            _this7.setDefaultMap();
          }
        }, 100);
        this.getDataBy();
      },
      clearRegion: function clearRegion() {
        var _this8 = this;

        this.bounds = null;
        this.map.data.forEach(function (data) {
          return _this8.map.data.remove(data);
        });
      },
      mapCheckboxes: function mapCheckboxes(key, event, name) {
        var filter = this.filters[key];
        var index = filter.indexOf(name);
        var isChecked = event.srcElement.checked;

        if (isChecked && index < 0) {
          filter.push(name);
        } else if (!isChecked && index >= 0) {
          filter.splice(index, 1);
        }

        this.filter();
      },
      autocompleteFocus: function autocompleteFocus() {
        this.focus = true;
      },
      autocompleteFocusOut: function autocompleteFocusOut() {
        this.focus = false;
      },
      autocompleteSelect: function autocompleteSelect(val) {
        this.filters.territory = val;
        this.autocompleteFocusOut();
        this.filter(true);
      },
      autocompleteClear: function autocompleteClear() {
        this.filters.territory = '';
        this.$refs.autocomplete.focus();
        this.filter();
        this.setDefaultMap();
      },
      autocompletePrevent: function autocompletePrevent(event) {
        if (event.keyCode == '40') {
          event.preventDefault();

          if (event.srcElement.nextElementSibling) {
            event.srcElement.nextElementSibling.focus();
          }
        }
      },
      autocompleteFocusItem: function autocompleteFocusItem(event) {
        if (event.srcElement.firstChild) {
          event.srcElement.firstChild.focus();
        }
      },
      autocompleteNavigate: function autocompleteNavigate(event) {
        if (event.keyCode == 38) {
          event.preventDefault();
          var previous = event.srcElement.previousSibling;

          if (previous) {
            previous.focus();
          }
        }

        if (event.keyCode == 40) {
          event.preventDefault();
          var next = event.srcElement.nextSibling;

          if (next) {
            next.focus();
          }
        }

        if (event.keyCode == 13) {
          event.srcElement.click();
        }
      },
      preventClick: function preventClick(event) {
        event.stopPropagation();
      },
      reset: function reset() {
        var _this9 = this;

        this.filters = {
          year: this.years[0].year,
          territory: '',
          modes: [],
          levels: [],
          populations: []
        };
        this.currentDomain = null;
        this.filter();

        if (this.region) {
          this.region = '';
          this.clearRegion();
          this.getData();
        }

        setTimeout(function () {
          _this9.setDefaultMap();
        }, 500);
      },

      /***********************************
      TERRITORIES
      ***********************************/
      setTerritories: function setTerritories() {
        var _this10 = this;

        var container = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.territoriesObj;
        var source = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.rawData;
        var obj = {};
        this.territories.forEach(function (territory) {
          var years = _this10.rawData.filter(function (el) {
            return el.territory === territory.name;
          });

          territory['years'] = years;

          if (!obj.hasOwnProperty(territory.name)) {
            obj[territory.name] = territory;
          }

          obj[territory.name]['years'] = {};
          years.forEach(function (year) {
            obj[territory.name]['years'][year.year] = year;
          });

          if (years.length) {
            obj[territory.name]['location'] = years[0].location;
          }
        });
        this.territoriesObj = obj;
      },
      setTerritoriesByDomain: function setTerritoriesByDomain() {
        var _this11 = this;

        var obj = {};

        _toConsumableArray(this.territories).forEach(function (territory) {
          var years = _this11.domainData.filter(function (el) {
            return el.territory === territory.name;
          });

          if (!obj.hasOwnProperty(territory.name)) {
            obj[territory.name] = _objectSpread({}, territory);
          }

          obj[territory.name]['years'] = {};

          _toConsumableArray(years).forEach(function (year) {
            obj[territory.name]['years'][year.year] = Object.assign({}, _objectSpread({}, year));
          });

          if (years.length) {
            obj[territory.name]['location'] = years[0].location;
          }
        });

        this.territoriesObjByDomain = obj;
      },
      getTerritory: function getTerritory(territory) {
        var current = !this.currentDomain ? _objectSpread({}, this.territoriesObj[territory]) : _objectSpread({}, this.territoriesObjByDomain[territory]);

        if (current) {
          this.currentTerritory = current;
          this.openDescription();
        }
      },

      /***********************************
      DESCRIPTION PANEL
      ***********************************/
      openDescription: function openDescription() {
        this.showDescription = true;
        this.currentYear = this.filters.year;
      },
      closeDescription: function closeDescription() {
        this.currentTerritory = null;
        this.showDescription = false;
      },
      getIcon: function getIcon(domain) {
        return 'icon-' + domain.slug;
      },
      getChip: function getChip(mode) {
        return 'chip-' + mode;
      },
      getValue: function getValue(key) {
        return this.currentTerritory.years[this.currentYear][key];
      },
      getValueByDomain: function getValueByDomain(key) {
        return this.currentTerritory.years[this.currentYear].services[0][key];
      },
      getIndex: function getIndex() {
        var _this12 = this;

        var index;
        Object.keys(this.currentTerritory.years).filter(function (key, i) {
          if (key == _this12.currentYear) {
            index = i;
          }
        });
        return index;
      },
      getKeyByIndex: function getKeyByIndex() {
        var delta = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
        var k;
        var index = this.getIndex();
        Object.keys(this.currentTerritory.years).filter(function (key, i) {
          if (i == index + delta) {
            k = key;
          }
        });
        return k;
      },
      hasPrev: function hasPrev() {
        return this.getIndex() > 0;
      },
      hasNext: function hasNext() {
        return this.getIndex() < Object.keys(this.currentTerritory.years).length - 1;
      },
      prev: function prev() {
        this.currentYear = this.getKeyByIndex(-1);
      },
      next: function next() {
        this.currentYear = this.getKeyByIndex(1);
      },
      hasIntercom: function hasIntercom() {
        return this.currentTerritory.years[this.currentYear].hasOwnProperty('intercommunality');
      },
      getIntercom: function getIntercom() {
        return this.currentTerritory.years[this.currentYear].intercommunality;
      },
      getChart: function getChart() {
        var serializer = new XMLSerializer();
        return serializer.serializeToString(this.setChart(this.getValue('modes'), 23));
      },

      /***********************************
      MAP GESTION
      ***********************************/
      initMap: function initMap() {
        var _this13 = this;

        this.map = new google.maps.Map(document.getElementById('map'), {
          center: {
            lat: 46.227638,
            lng: 2.213749
          },
          zoom: 6,
          zoomControl: false,
          scaleControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          styles: mapStyles
        });
        this.setCustomMarkerPrototype();
        this.map.data.setStyle({
          fillColor: '#2A89C5',
          strokeColor: '#074885',
          strokeWeight: 1
        });
        this.map.data.addListener('addfeature', function (e) {
          _this13.bounds = new google.maps.LatLngBounds();

          _this13.processPoints(e.feature.getGeometry(), _this13.bounds.extend, _this13.bounds);

          _this13.map.fitBounds(_this13.bounds);
        });
      },
      processPoints: function processPoints(geometry, callback, thisArg) {
        var _this14 = this;

        if (geometry instanceof google.maps.LatLng) {
          callback.call(thisArg, geometry);
        } else if (geometry instanceof google.maps.Data.Point) {
          callback.call(thisArg, geometry.get());
        } else {
          geometry.getArray().forEach(function (g) {
            _this14.processPoints(g, callback, thisArg);
          });
        }
      },
      filterMap: function filterMap() {
        var _this15 = this;

        if (this.map) {
          this.markers = this.filteredData.map(function (marker, index) {
            var m = new _this15.customMarker(marker, index);
            return m;
          });

          if (this.cluster) {
            this.cluster.clearMarkers();
            this.cluster.addMarkers(this.markers);
          } else {
            this.cluster = new MarkerClusterer(this.map, this.markers, {
              minimumClusterSize: 10
            });
          }
        }
      },
      zoom: function zoom() {
        var back = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var zoom = this.map.getZoom() + (back ? -1 : 1);
        this.map.setZoom(zoom > 2 ? zoom : 2);
      },
      centerMap: function centerMap(data) {
        this.map.setCenter({
          lat: data.lat,
          lng: data.long
        });
        this.map.setZoom(10);
      },
      setDefaultMap: function setDefaultMap() {
        this.map.setCenter({
          lat: 46.227638,
          lng: 2.213749
        });
        this.map.setZoom(6);
      },
      setChart: function setChart(modes) {
        var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 19;

        if (modes) {
          var total = modes.reduce(function (prev, curr) {
            return prev + curr.count;
          }, 0);
          var list = ['GD', 'GC', 'RD', 'NC'];
          var dataSectors = list.map(function (key) {
            if (modes.some(function (el) {
              return el.mode == key;
            })) {
              var val = modes.filter(function (el) {
                return el.mode == key;
              })[0].count;
              return {
                percentage: modes.filter(function (el) {
                  return el.mode == key;
                })[0].count / total,
                val: val,
                total: total
              };
            }

            return {
              percentage: 0
            };
          });
          var data = {
            size: size,
            sectors: dataSectors
          };
          var sectors = calculateSectors(data);
          var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
          svg.setAttributeNS(null, 'style', "width: " + data.size + "px; height: " + data.size + "px");
          sectors.map(function (sector) {
            var newSector = document.createElementNS("http://www.w3.org/2000/svg", "path");
            newSector.setAttributeNS(null, 'fill', sector.color);
            newSector.setAttributeNS(null, 'd', 'M' + sector.L + ',' + sector.L + ' L' + sector.L + ',0 A' + sector.L + ',' + sector.L + ' 0 ' + sector.arcSweep + ',1 ' + sector.X + ', ' + sector.Y + ' z');
            newSector.setAttributeNS(null, 'transform', 'rotate(' + sector.R + ', ' + sector.L + ', ' + sector.L + ')');
            svg.appendChild(newSector);
          });
          return svg;
        }
      },
      setCustomMarkerPrototype: function setCustomMarkerPrototype() {
        var that = this;

        this.customMarker = function CustomMarker(marker, index) {
          this.coords = new google.maps.LatLng(marker.location.lat, marker.location.long);
          this.territory = marker.territory;
          this.index = index;
          this.marker = marker;
        };

        this.customMarker.prototype = new google.maps.OverlayView();

        this.customMarker.prototype.draw = function () {
          var _this16 = this;

          var div = this.div;

          if (!div) {
            var panes = this.getPanes();
            div = this.div = document.createElement('div');
            div.className = 'marker';
            div.innerHTML = div.innerHTML + '<span>' + this.territory + '</span><div></div>';

            if (this.marker.hasOwnProperty('modes')) {
              if (this.marker.modes.length > 1) {
                div.querySelector('div').appendChild(that.setChart(this.marker.modes));
              } else {
                div.querySelector('div').classList.add('is-' + this.marker.modes[0].mode);
              }
            } else {
              div.querySelector('div').classList.add('is-' + this.marker.services[0].mode.short_code);
            }

            panes.overlayImage.appendChild(div);
            google.maps.event.addDomListener(this.div, "click", function () {
              that.getTerritory(that.filteredData[_this16.index].territory);
            });
          }

          var point = this.getProjection().fromLatLngToDivPixel(this.coords);

          if (point) {
            div.style.left = point.x - 15 + 'px';
            div.style.top = point.y - 15 + 'px';
          }

          this.div = div;
        };

        this.customMarker.prototype.remove = function () {
          if (this.div) {
            google.maps.event.clearInstanceListeners(this.div);
            this.div.parentNode.removeChild(this.div);
            this.div = null;
          }
        };

        this.customMarker.prototype.getPosition = function () {
          return this.coords;
        };

        this.customMarker.prototype.getDraggable = function () {};
      }
    }
  });
}

function initMap() {
  if (window.vueHome) {
    window.vueHome.initMap();
  }
}

function calculateSectors(data) {
  var sectors = [];
  var colors = ["#074885", "#FFE600", "#EF7D00", "#EEF0FA"];
  var l = data.size / 2;
  var a = 0;
  var aRad = 0;
  var z = 0;
  var x = 0;
  var y = 0;
  var X = 0;
  var Y = 0;
  var R = 0;
  data.sectors.map(function (item, key) {
    var a = 360 * item.percentage;
    var aCalc = a > 180 ? 360 - a : a;
    var aRad = aCalc * Math.PI / 180;
    var z = Math.sqrt(2 * l * l - 2 * l * l * Math.cos(aRad));
    var arcSweep;

    if (aCalc <= 90) {
      x = l * Math.sin(aRad);
    } else {
      x = l * Math.sin((180 - aCalc) * Math.PI / 180);
    }

    y = Math.sqrt(z * z - x * x);
    Y = y;

    if (a <= 180) {
      X = l + x;
      arcSweep = 0;
    } else {
      X = l - x;
      arcSweep = 1;
    }

    sectors.push({
      percentage: item.percentage,
      color: colors[key],
      arcSweep: arcSweep,
      L: l,
      X: X,
      Y: Y,
      R: R
    });
    R = R + a;
  });
  return sectors;
}

var mapStyles = [{
  "featureType": "administrative",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#2989c5"
  }]
}, {
  "featureType": "administrative.province",
  "elementType": "geometry.stroke",
  "stylers": [{
    "visibility": "on"
  }]
}, {
  "featureType": "landscape",
  "elementType": "all",
  "stylers": [{
    "color": "#f2f2f2"
  }]
}, {
  "featureType": "poi",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "road",
  "elementType": "all",
  "stylers": [{
    "saturation": -100
  }, {
    "lightness": 45
  }, {
    "visibility": "off"
  }]
}, {
  "featureType": "road.highway",
  "elementType": "all",
  "stylers": [{
    "visibility": "simplified"
  }, {
    "lightness": "0"
  }]
}, {
  "featureType": "road.arterial",
  "elementType": "labels.icon",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "transit",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "water",
  "elementType": "all",
  "stylers": [{
    "color": "#aadbff"
  }, {
    "visibility": "on"
  }]
}];