"use strict";

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

if (document.getElementById('js-service')) {
  window.vueHome = new Vue({
    delimiters: ['[[', ']]'],
    el: '#js-service',
    data: {
      domain: '',
      currentYear: '',
      chart: null,
      modes: [],
      rawModes: [],
      stats: {},
      texts: {
        'kf_figure': '',
        'kf_comment': '',
        'dyn_figure': '',
        'dyn_comment': '',
        'total': ''
      },
      statics: [{
        endpoint: 'modes',
        key: 'rawModes',
        useStorage: false,
        callback: function callback(context) {
          return context.getStats();
        }
      }]
    },
    mounted: function mounted() {
      var _this = this;

      this.domain = window.location.pathname.replace('/services/', '');
      this.statics.forEach(function (s) {
        return _this.getStaticData(s);
      });
    },
    computed: {},
    methods: {
      getStaticData: function getStaticData(path) {
        var _this2 = this;

        if (localStorage.getItem('igd-' + path.key) && path.useStorage) {
          this[path.key] = JSON.parse(localStorage.getItem('igd-' + path.key));
        } else {
          axios.get("/api/v1/".concat(path.endpoint), {
            params: path.hasOwnProperty('params') ? Object.assign(path.params, {
              limit: 300
            }) : {
              limit: 300
            }
          }).then(function (response) {
            _this2[path.key] = response.data.results;
            localStorage.setItem('igd-' + path.key, JSON.stringify(_this2[path.key]));

            if (path.hasOwnProperty('callback')) {
              path.callback(_this2);
            }
          });
        }
      },
      getStats: function getStats() {
        var _this3 = this;

        axios.get("/api/v1/domains/".concat(this.domain, "/stats")).then(function (response) {
          _this3.stats = response.data.reduce(function (prev, curr, index) {
            prev[curr.year] = curr;
            return prev;
          }, {});
          Object.keys(_this3.stats).forEach(function (key) {
            if (key == _this3.currentYear) {
              _this3.setCurrentChart();
            }
          });
        });
      },
      setCurrentChart: function setCurrentChart() {
        var year = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.currentYear;
        var event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        this.currentYear = year;

        if (event) {
          var data = event.srcElement.dataset;
          this.$set(this.texts, 'kf_figure', data.kfFigure);
          this.$set(this.texts, 'kf_comment', data.kfComment);
          this.$set(this.texts, 'dyn_figure', data.dynFigure);
          this.$set(this.texts, 'dyn_comment', data.dynComment);
        }

        if (this.stats[year]) {
          var modes = _objectSpread({}, this.stats[year].stats.modes);

          var total = this.stats[year].stats.count;
          modes = Object.keys(modes).map(function (key) {
            return {
              mode: key,
              count: modes[key]
            };
          });
          this.chart = this.setChart(modes, 150);
          this.texts.total = total;
          this.setModes(modes, total);
        }
      },
      setModes: function setModes(modes, total) {
        var _this4 = this;

        this.modes = [];
        this.rawModes.filter(function (mode) {
          if (modes.some(function (m) {
            return mode.short_code == m.mode;
          })) {
            var curr = modes.filter(function (m) {
              return m.mode == mode.short_code;
            })[0];

            var m = _objectSpread({}, mode);

            m['percent'] = (curr.count / total * 100).toFixed(2);

            _this4.modes.push(m);
          }
        });
        this.modes;
      },
      getChart: function getChart() {
        if (this.chart) {
          var serializer = new XMLSerializer();
          return serializer.serializeToString(this.chart);
        }
      },
      getChip: function getChip(mode) {
        return 'chip-' + mode;
      },
      setChart: function setChart(modes) {
        var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 19;

        if (modes) {
          var total = modes.reduce(function (prev, curr) {
            return prev + curr.count;
          }, 0);
          var list = ['GD', 'GC', 'RD', 'NC'];
          var dataSectors = list.map(function (key) {
            if (modes.some(function (el) {
              return el.mode == key;
            })) {
              var val = modes.filter(function (el) {
                return el.mode == key;
              })[0].count;
              return {
                percentage: modes.filter(function (el) {
                  return el.mode == key;
                })[0].count / total,
                val: val,
                total: total
              };
            }

            return {
              percentage: 0
            };
          });
          var data = {
            size: size,
            sectors: dataSectors
          };
          var sectors = calculateSectors(data);
          var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
          svg.setAttributeNS(null, 'style', "width: " + data.size + "px; height: " + data.size + "px");
          sectors.map(function (sector) {
            var newSector = document.createElementNS("http://www.w3.org/2000/svg", "path");
            newSector.setAttributeNS(null, 'fill', sector.color);
            newSector.setAttributeNS(null, 'd', 'M' + sector.L + ',' + sector.L + ' L' + sector.L + ',0 A' + sector.L + ',' + sector.L + ' 0 ' + sector.arcSweep + ',1 ' + sector.X + ', ' + sector.Y + ' z');
            newSector.setAttributeNS(null, 'transform', 'rotate(' + sector.R + ', ' + sector.L + ', ' + sector.L + ')');
            svg.appendChild(newSector);
          });
          return svg;
        }
      }
    }
  });
}

function calculateSectors(data) {
  var sectors = [];
  var colors = ["#074885", "#FFE600", "#EF7D00", "#EEF0FA"];
  var l = data.size / 2;
  var a = 0;
  var aRad = 0;
  var z = 0;
  var x = 0;
  var y = 0;
  var X = 0;
  var Y = 0;
  var R = 0;
  data.sectors.map(function (item, key) {
    var a = 360 * item.percentage;
    var aCalc = a > 180 ? 360 - a : a;
    var aRad = aCalc * Math.PI / 180;
    var z = Math.sqrt(2 * l * l - 2 * l * l * Math.cos(aRad));
    var arcSweep;

    if (aCalc <= 90) {
      x = l * Math.sin(aRad);
    } else {
      x = l * Math.sin((180 - aCalc) * Math.PI / 180);
    }

    y = Math.sqrt(z * z - x * x);
    Y = y;

    if (a <= 180) {
      X = l + x;
      arcSweep = 0;
    } else {
      X = l - x;
      arcSweep = 1;
    }

    sectors.push({
      percentage: item.percentage,
      color: colors[key],
      arcSweep: arcSweep,
      L: l,
      X: X,
      Y: Y,
      R: R
    });
    R = R + a;
  });
  return sectors;
}